(function($)
{
  $.fn.customScrollbar = function()
  {
    var $document = $(document);

    return this.each(function()
    {
      var $element = $(this),
      element = this;

      $element.addClass('custom-scroll');

      var $scrollbar = $('<div class="custom-scrollbar"/>');
      var $arrowTop = $('<div class="custom-scroll-arrow-top"/>');
      var $arrowBottom = $('<div class="custom-scroll-arrow-bottom"/>');
      $scrollbar.append($arrowTop).append($arrowBottom);
      $element.after($scrollbar);

      if(element.scrollHeight === element.clientHeight)
      {
        $scrollbar.addClass('no-scrolling');
      }

      // Scroll up the height of the window
      $arrowTop.on('click', function()
      {
        element.scrollTop -= $element.height() / 4;
      });

      // Scroll down the height of the window
      $arrowBottom.on('click', function()
      {
        element.scrollTop += $element.height() / 4;
      });

      // Scroll based on mousewheel action (uses jquery.mousewheel.js)
      $element.on('mousewheel', function(event)
      {
        element.scrollTop -= event.deltaY * event.deltaFactor;
      });

      // Event handler for when page is scrolled to top
      $document.on('custom-scroll-top', function()
      {
        $scrollbar.addClass('scrolled-up');
        $scrollbar.removeClass('scrolled-down');
      });

      // Event handler for when page is scrolled to bottom
      $document.on('custom-scroll-bottom', function()
      {
        $scrollbar.removeClass('scrolled-up');
        $scrollbar.addClass('scrolled-down');
      });

      // Event handler for when page is being scrolled
      $document.on('custom-scroll-midway', function()
      {
        $scrollbar.removeClass('scrolled-down');
        $scrollbar.removeClass('scrolled-up');
      });

      // Check for scroll positions an trigger custom events when on top or bottom
      $element.on('scroll touchmove', function()
      {
        if (element.scrollTop === 0)
        {
          $document.trigger('custom-scroll-top');
        }
        else if (element.scrollHeight - element.scrollTop === element.clientHeight)
        {
          $document.trigger('custom-scroll-bottom');
        }
        else
        {
          $document.trigger('custom-scroll-midway');
        }
      });
      // Trigger the scroll event once so the custom-scroll-top event can be triggered when already on top
      $element.trigger('scroll');


    });
  };
})(jQuery);
