(function ($) {
  $.fn.slideshow = function () {
    var $document = $(document);
    var $lightboxModal = $('.lightbox-modal');
    var $sliderContainer = $lightboxModal.find('.slider');

    // Not really optimized to be used on multiple elements. Use $.slideshow on a single collection item only.
    return this.each(function () {
      var $jQueryElement = $(this);

      var $slickHandle = null;
      // Get all images on single portfolio page
      var $allImages = $jQueryElement.find('.cp-portfolio-single img');

      /**
       * Initializes slideshow
       */
      var initSlideshow = function () {
        // When navigating using AJAX, slider container may already be populated
        // We must also remove the slick classes, otherwise slick cannot re-initialize
        $sliderContainer.empty().removeClass(['slick-initialized', 'slick-slider']);

        // Set wrapper padding in rem
        var wrapperPaddingRem;
        if ($document.width() >= 770) {
          wrapperPaddingRem = 4;
        } else {
          wrapperPaddingRem = 0.9375;
        }
        $lightboxModal.find('.wrapper').css('padding', wrapperPaddingRem + 'rem');

        // Calculate 1rem in pixels @see https://stackoverflow.com/questions/45001097/convert-rem-to-px-without-reflow
        var remInPx = parseInt(
          getComputedStyle(document.documentElement).fontSize
        );

        // Calculate inner width/height of wrapper in vw/vh
        var wrapperWidth = window.innerWidth - 2 * wrapperPaddingRem * remInPx;
        var wrapperHeight = window.innerHeight - 2 * wrapperPaddingRem * remInPx;

        // …clone all images to lightbox
        $allImages
          .css('cursor', 'pointer')
          .clone()
          .removeClass()
          .removeAttr('loading')
          .attr('sizes', '100vw') // Set size to full width (from responsive picture)
          .css('margin', '0 auto') // Center image horizontally
          .appendTo($sliderContainer)
          .wrap('<div class="img-wrapper"></div>');

        // Calculate the width each image will take (it has to fit in the .wrapper container)
        // and assign it to the images' "sizes" attribute, for responsiveness
        // We could use "object-fit: contain" to achieve the same, but then the image has always
        // a width of 100% and srcset wouldn't load the smallest image required.
        $sliderContainer.find('img').each(function () {
          var $this = $(this);
          var originalWidth = $this.attr('width');
          var originalHeight = $this.attr('height');

          // We assume, the image is constrained by wrapper height
          var newWidth = Math.ceil(wrapperHeight / originalHeight * originalWidth);
          // If new width ist still wider than wrapper width, constrain by wrapper width
          if (newWidth > wrapperWidth) {
            newWidth = wrapperWidth;
          }

          this.sizes = Math.ceil(newWidth / window.innerWidth * 100) + 'vw';
        });
      };

      /**
       * When closing the modal, Slick looses its sizes. Therefore
       * we need to re-initialize Slick every time we open the
       * modal.
       *
       * @param initialSlide
       */
      var resetSlick = function (initialSlide) {
        // If Slick was already initialized, remove it first
        if ($slickHandle) {
          $slickHandle.slick('unslick');
        }

        // Start slideshow
        $slickHandle = $sliderContainer.slick();

        // …go to slide nr of clicked image
        $slickHandle.slick('slickGoTo', initialSlide, true);

        // …remove onload handler attribute, causes errors in Chrome…
        $slickHandle
          .find('img')
          .removeAttr('onload');
      };

      /**
       * Opens the clicked image in the lightbox.
       */
      $allImages.on('click', function () {
        // Lazy initialize slideshow
        // if ($sliderContainer.children().length === 0) {
        if ($slickHandle === null) {
          initSlideshow();
        }

        // Must re-initialize on every modal opening
        resetSlick($allImages.index(this));

        // Open modal
        $lightboxModal.addClass('open').fadeIn();
      });

      /**
       * Closes open modal(s).
       */
      var closeModal = function () {
        $document
          .find('.modal.open')
          .removeClass('open')
          .fadeOut();
      };

      /**
       * Close modal on click
       */
      $document.on('click', '.modal .wrapper', function (event) {
        // Ignore clicks that didn't happen on the wrapper itself (ie content elements above like form)
        if (event.target !== this) {
          return;
        }
        closeModal();
      });

      /**
       * Close modal when clicking "close" button
       */
      $document.on('click', '.modal .icon-close', function (event) {
        event.preventDefault();
        closeModal();
      });

      /**
       * Close modal when hitting ESC
       * and navigate gallery with left/right
       * arrow keys.
       */
      $document.keydown(function (event) {
        if (event.keyCode === 27) {
          closeModal();
        }

        if ($slickHandle) {
          if (event.keyCode === 37) {
            $slickHandle.slick('slickPrev');
          } else if (event.keyCode === 39) {
            $slickHandle.slick('slickNext');
          }
        }
      });
    });
  };
})(jQuery);
