(function($)
{
  $(function()
  {
    var $body = $('body');
    var docWidth = $(document).width();

    // Update body class
    $body.removeClass('no-js').addClass('has-js');

    // Tag body when using iPad (don't use class on body, as these classes are changed by ajax)
    if(navigator.userAgent.match(/iPad/i) != null)
    {
      $('html').addClass('isIpad');
    }

    // Auto submit filter
    var $filterForm = $('.filter-form');
    $filterForm.find('select').on('change', function(e)
    {
      $filterForm.submit();
    });

    // Style select fields
    $('select').styleSelect();

    if (docWidth >= 768)
    {
      // Add custom scrolling to main content
      $('.main').customScrollbar();

      // When loading homepage, show fade-in animation of news button
      $('body.home .nav-ext').hide().show(500);
    }

    /**
     * Enable image lightbox on single project pages.
     */
    $('#container').slideshow();

    /**
     * Toggle nav containers on mobile devices
     */
    var $navContainer = $('.nav-primary');
    $('.navbar-toggle').on('click', function()
    {
      $navContainer.slideToggle(300);
    });
    if (docWidth < 768)
    {
      $navContainer.slideUp(0);
      // Also toggle menu when clicking on logo on mobile devices
      $('#w2h-mobile-logo').on('click', function(event)
      {
        event.preventDefault();
        $navContainer.slideToggle(300);
      });
    }
  });

})(jQuery);
