(function($)
{
  /**
   * Styled select fields.
   * Based on http://codepen.io/wallaceerick/pen/ctsCz
   */
  $.fn.styleSelect = function()
  {
    return this.each(function()
    {
      var $this = $(this),
        numberOfOptions = $this.children('option').length;

      $this.addClass('hidden');
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="select-styled"></div>');

      var $styledSelect = $this.next('div.select-styled');
      $styledSelect.text($this.children(':selected').eq(0).text());

      var $list = $('<ul />', {
        'class': 'select-options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++)
      {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val(),
          'class': $this.children('option').eq(i).is(':disabled') ? 'select-disabled' : ''
        }).appendTo($list);
      }

      var $listItems = $list.children('li');

      $styledSelect.on('click', function(e)
      {
        e.stopPropagation();
        var $this = $(this);
        $this.toggleClass('active').next('ul.select-options').slideToggle(200);
        $('div.select-styled.active').not($this).each(function()
        {
          $(this).removeClass('active').next('ul.select-options').slideUp(200);
        });
      });

      $listItems.not('.select-disabled').on('click', function(e)
      {
        var $li = $(this);
        e.stopPropagation();
        $styledSelect.text($li.text()).removeClass('active');
        $this.val($li.attr('rel'));
        $this.parents('form').submit();
        $list.slideUp(200);
      });

      $(document).on('click', function()
      {
        $styledSelect.removeClass('active');
        $list.slideUp(200);
      });

    });
  };

})(jQuery);
