(function($)
{
  $.fn.toggleTeamCv = function()
  {
    return this.each(function()
    {
      $(this).on('click', function()
      {
        $(this).toggleClass('cp-show-cv');
        $(this).find('.cp-team-cv-tag').toggle();
      });
    });
  };

  $('.cp-team-has-cv').toggleTeamCv();

})(jQuery);
