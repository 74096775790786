(function($)
{
  /**
   * An event is treggered and an animation takes place
   * @type {boolean}
   */
  var isAnimated = false;

  /**
   * Options for the plugin
   */
  var settings;
  /**
   * all methods of the plugin
   * @type {{init: methods.init, start: methods.start, reset: methods.reset}}
   */
  var methods = {
    /**
     * Initi the plugin
     * @param options
     * @returns {*}
     */
    init: function(options)
    {
      settings = $.extend({
        animationTime: 800
      }, options);

      return this.each(function()
      {
        var that = this;
        $(this).parent().css("perspective", "1000px");
        $(document).on("mousemove", function(event)
        {
          if (!isAnimated)
          {
            var x = -60 * Math.round(Math.max(event.pageY, 1)) / $(window).height() + 30;
            var y = 60 * Math.round(Math.max(event.pageX, 1)) / $(window).width() - 30;
            var translation = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
            var transition = "inherit";

            $(that).css({
              "transform": translation,
              "-moz-transform": translation,
              "-o-transform": translation,
              "-webkit-transform": translation,
              "-moz-transition": transition,
              "-o-transition": transition,
              "-webkit-transition": transition,
              "transition": transition
            });
          }
        });
      });
    },
    /**
     * start animation after an event
     * @param direction
     */
    start: function(direction)
    {
      isAnimated = true;
      var transform;
      var transition = "transform " + settings.animationTime + "ms";

      switch (direction)
      {
        case "top":
          transform = "rotateX(96deg)";
          break;
        case "bottom":
          transform = "rotateX(-84.5deg)";
          break;
        case "left":
          transform = "rotateY(-90deg)";
          break;
        case "right":
          transform = "rotateY(90deg)";
          break;
      }
      $(this).css({
        "-moz-transition": transition,
        "-o-transition": transition,
        "-webkit-transition": transition,
        "transition": transition,
        "-webkit-transform": transform,
        "-moz-transform": transform,
        "-o-transform": transform,
        "transform": transform
      });

      setTimeout(function()
      {
        isAnimated = false;
      }, settings.animationTime);
    },

    /**
     * Reset the animation to the start composition
     */
    reset: function()
    {
      var transition = "transform " + settings.animationTime + "ms";
      var transform = "rotateX(0deg) rotateY(0deg)";
      $(this).css({
        "-moz-transition": transition,
        "-o-transition": transition,
        "-webkit-transition": transition,
        "transition": transition,
        "-webkit-transform": transform,
        "-moz-transform": transform,
        "-o-transform": transform,
        "transform": transform
      });
    }
  };

  /**
   * declare plugin with methods
   * @param methodOrOptions
   * @returns {*}
   */
  $.fn.w2hAnimation = function(methodOrOptions)
  {
    if (methods[methodOrOptions])
    {
      return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof methodOrOptions === 'object' || !methodOrOptions)
    {
      // Default to "init"
      return methods.init.apply(this, arguments);
    } else
    {
      $.error('Method ' + methodOrOptions + ' does not exist on jQuery.tooltip');
    }
  };
})(jQuery);
