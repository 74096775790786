(function($)
{
  var $body = $('body');

  if ($body.width() >= 768)
  {
    var $container = $('#container'),
      $newsButton = $('.nav-ext'),
      $navMenu = $('.nav-primary'),
      $modalCover = $('<div class="modal-cover" style="position:fixed;left:0;top:0;width:100%;height:100%;"/>'),
      duration = 500,
      easing = 'swing',
      ajaxRequest = null,
      currentPushState = {animateContainer: true};  // Will never be used in this initialized state (first page load) . If visitor clicks back after first page load, he leaves page)

    // All clicks on menu items should animate the new ajax page load
    $navMenu.find('a').addClass('animate-loading');

    // Animate logo
    var $w2hLogo = $('#w2h-logo').w2hAnimation();

    /**
     * Returns the animation from-direction for the submitted pathname (=relative URL)
     *
     * @param pathname
     * @returns {*}
     */
    var getAnimationDirection = function(pathname)
    {
      switch (pathname)
      {
        case '/w2h-architekten/':
          return 'top';
        case '/kontakt/':
          return 'left';
        case '/team/':
          return 'right';
        case '/portfolio/':
          return 'bottom';
      }
      return 'popup';
    };

    /**
     * Generates the left/top positions for the submitted target
     * (one of top/bottom/left/right).
     *
     * @param target
     * @returns {*}
     */
    var getLocation = function(target)
    {
      var location;

      switch (target)
      {
        case 'top':
          location = {
            left: 0,
            top: -1 * $container.height() - parseInt($body.css('padding-top')) + 'px'
          };
          break;
        case 'right':
          location = {
            left: $body.innerWidth() + 'px',
            top: 0
          };
          break;
        case 'bottom':
          location = {
            left: 0,
            top: $body.innerHeight() + 'px'
          };
          break;
        case 'left':
          location = {
            left: -1 * $container.width() - $body.innerWidth() / 2 + 'px',
            top: 0
          };
          break;
        default:
          location = null;
      }

      return location;
    };

    /**
     * Closes the container. If it has a target top/bottom/left/right
     * it is moved away, otherwise it is faded out.
     *
     * @param goHome Displays homepage when set to true (shows news logo, logo animation and resets URL)
     * @param setPushState If set to true, window.history.pushState will be set when going home after container is closed
     * @returns Promise of deferred object to check when done closing
     */
    var closeContainer = function(goHome, setPushState)
    {
      var deferredObj = $.Deferred();
      var location = getLocation($container.data('target'));
      $container.removeClass('open');
      $body.removeClass('open-container');

      // Remove all active states on menu buttons
      $navMenu.find('.active').removeClass('active');

      // Make sure, modal cover hides
      $modalCover.fadeOut({
        duration: duration,
        easing: easing
      });

      /**
       * Callback on close animation
       */
      var onClose = function()
      {
        deferredObj.resolve();

        if (goHome)
        {
          if(setPushState)
          {
            window.history.pushState({animateContainer: true}, 'home', '/');
          }
          $newsButton.show(duration);
          $body.attr('class', 'home page has-js');
        }
      };

      // If no previous animation found, simply fade out
      if (location == null)
      {
        $container.fadeOut({
          duration: duration,
          easing: easing,
          complete: onClose
        });
      }
      else
      {
        $container.animate(location, duration, easing, onClose);
      }

      return deferredObj.promise();
    };

    /**
     * Opens the container by placing it to the top/bottom/left/right
     * position and moving it to the center, or by fading it in (default).
     */
    var openContainer = function()
    {
      $newsButton.hide(duration);

      var target = $container.data('target');

      /**
       * Callback on open
       */
      var onOpen = function()
      {
        $container.find('.main').customScrollbar();
        $w2hLogo.w2hAnimation('reset');
        $container.addClass('open');
        $body.addClass('open-container');
      };

      // If target ist top/bottom/left/right
      if (target === 'top' || target === 'bottom' || target === 'left' || target === 'right')
      {
        // Make sure, container is visible
        $container.show();

        // Move container to starting position
        $container.css(getLocation($container.data('target')));
        // Animate container to center
        $container.animate({
          left: 0,
          top: 0,
          right: 'auto',
          bottom: 'auto'
        }, duration, easing, onOpen);
      }
      else
      {
        // Make sure container is hidden before fading in
        $container.fadeOut(0);
        // Center container
        $container.css({left: 'auto', top: 'auto'});
        // Display modal cover
        $modalCover.fadeIn({
          duration: duration,
          easing: easing
        });
        $container.fadeIn({
          duration: duration,
          easing: easing,
          complete: onOpen
        });
      }
    };

	  /**
     * Loads the page from the submitted href URL and initiates the appropriate animation.
     * If setPushState is set to true, the URL in the browsers address bar will be updated
     * (not required, if the history.back() event triggers loadPage())
     *
     * @param href URL of page to load
     * @param animateContainer If set to true, container opening/closing will be animated
     * @param setPushState Whether to update the browsers address bar or not
     * @param pushStateTitle Title that will be added to the browser history when using pushState
     */
    var loadPage = function(href, animateContainer, setPushState, pushStateTitle)
    {
      // If same page is requested as is already open or homepage is requested, just close content and show homepage
      if (href === '/' || (animateContainer && href === $container.data('href') && $container.hasClass('open')))
      {
        closeContainer(true, setPushState);
      }
      // Otherwise request new content
      else
      {
        var closePromise = animateContainer ? closeContainer(false, setPushState) : $.Deferred().resolve();

        $body.addClass('ajax-loading');
        // Abort running Ajax requests
        if (ajaxRequest)
        {
          ajaxRequest.abort();
        }

        ajaxRequest = $.ajax({
          url: href,
          type: 'GET',
          complete: function()
          {
            $body.removeClass('ajax-loading');
            ajaxRequest = null;
          }
        });

        $.when(ajaxRequest, closePromise).done(function(a, b)
        {
          var $newContent = $(a[0]);
          $newContent.find('select').styleSelect();
          if(setPushState)
          {
            if (!pushStateTitle)
            {
              pushStateTitle = 'title';
            }
            window.history.pushState({animateContainer: animateContainer}, pushStateTitle, href);
          }

          // Activate main menu link that corresponds to the href to load
          $('.banner .nav a').each(function()
          {
            if(this.pathname === href)
            {
              $(this).parent('li').addClass('active');
            }
          });

          if (animateContainer)
          {
            $container.data('href', href);
            $container.data('target', getAnimationDirection(href));
            $container.html($newContent);
            $container.find('.cp-team-has-cv').toggleTeamCv();
            $container.slideshow();
            // Add classes submitted by ajax (stored as data attribute on page-header)
            $body.attr('class', $container.find('.page-header').data('body-class'));
            $container.ajaxify();
            $w2hLogo.w2hAnimation('start', $container.data('target'));
            openContainer();
          }
          else
          {
            $container.fadeOut(200, easing, function()
            {
              $container.html($newContent);
              $container.find('.cp-team-has-cv').toggleTeamCv();
              $container.slideshow();
              // Add classes submitted by ajax (stored as data attribute on page-header). Container stays open
              $body.attr('class', $container.find('.page-header').data('body-class')).addClass('open-container');
              $container.ajaxify();
              $container.fadeIn(200, easing, function()
              {
                $container.find('.main').customScrollbar();
              });
            });
          }
        });
      }
    };

    /**
     * Transforms all relative links and form submits in the
     * jQuery container to AJAX calls.
     */
    $.fn.ajaxify = function()
    {
      var $this = $(this[0]);

      // Ajaxify all relative links (exclude external and mailto links)
      $this.find('a').not('[href^="mailto:"],[target^="_blank"]').each(function()
      {
        var $a = $(this);
        var href = $a.get(0).pathname + $a.get(0).search;  // a.pathname is always the relative URL!

        // Ignore absolute links, mailto links and anchors with class .no-ajax
        if (/^https?:\/\/|^\/\/|^mailto:/i.test(href) || $a.hasClass('no-ajax'))
        {
          return true;
        }

        $a.on('click', function(event)
        {
          loadPage(href, $a.hasClass('animate-loading'), true, $a.attr('title'));
          event.preventDefault();
        });
      });

      // Ajaxify forms
      $this.find('form').each(function()
      {
        var $form = $(this);
        var action = $form.attr('action');

        // Ignore absolute urls and forms with class .no-ajax
        if (/^https?:\/\/|^\/\//i.test(action) || $form.hasClass('no-ajax'))
        {
          return true;
        }

        $form.on('submit', function(event)
        {
          $body.addClass('ajax-loading');
          // Abort running Ajax requests
          if (ajaxRequest)
          {
            ajaxRequest.abort();
          }

          var formData = $form.serialize();

          ajaxRequest = $.ajax({
            url: action,
            type: $form.attr('method').toUpperCase(),
            data: formData,
            complete: function()
            {
              $body.removeClass('ajax-loading');
              ajaxRequest = null;
            },
            success: function(data)
            {
              var $newContent = $(data);
              var queryString = action + (formData === '' ? '' : '?' + formData);
              $newContent.find('select').styleSelect();
              window.history.pushState({animateContainer: false}, 'Formular übermitteln', queryString);
              $container.data('href', queryString);
              $container.html($newContent);
              $container.find('.cp-team-has-cv').toggleTeamCv();
              $container.find('.main').customScrollbar();
              $container.ajaxify();
            }
          });

          event.preventDefault();
        });

      });
    };

	  /**
     * Define a pushState event
     *
     * http://stackoverflow.com/questions/4570093/how-to-get-notified-about-changes-of-the-history-via-history-pushstate
     */
    (function(history){
        var pushState = history.pushState;
        history.pushState = function(state) {
            if (typeof history.onpushstate === "function") {
                history.onpushstate({state: state});
            }
            return pushState.apply(history, arguments);
        };
    })(window.history);

	  /**
     * Listen to pushState event, then update the global var
     *
     * @param event
     */
    history.onpushstate = function(event)
    {
      currentPushState = event.state;
    };

	  /**
     * Listen to popState event (gets fired when clicking
     * forward or back button in browser)
     */
    window.onpopstate = function(event)
    {
      // Use same animation state as was used when loading the current page (current push state)
      var animate = currentPushState.animateContainer;
      // Set state from new page (requested by onpopstate event) as current state
      currentPushState = event.state;
      loadPage(location.pathname, animate, false, '');
    };

	  /**
     * Close open container when clicking on modal background cover
     */
    $modalCover.on('click', function()
    {
      closeContainer(true, true);
    });

	  /**
     * Close open container when clicking on a .close-container element
     */
    $(document).on('click', '.close-container', function(event)
    {
      closeContainer(true, true);
      event.preventDefault();
    });

	  /**
     * Initialize page
     */
    $body.prepend($modalCover);
    $body.ajaxify();
  }

})(jQuery);
